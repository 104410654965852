@keyframes tiltShake {
    0% {
        transform: rotate(0deg) scale(1);
    }
    10% {
        transform: rotate(-0.75deg) scale(1.03);
    }
    20% {
        transform: rotate(0.75deg) scale(1.03);
    }
    30% {
        transform: rotate(-0.75deg) scale(1.03);
    }
    40% {
        transform: rotate(0.75deg) scale(1.03);
    }
    50% {
        transform: rotate(0deg) scale(1);
    }
    60% {
        transform: rotate(0deg) scale(1);
    }
    70% {
        transform: rotate(0deg) scale(1);
    }
    80% {
        transform: rotate(0deg) scale(1);
    }
    90% {
        transform: rotate(0deg) scale(1);
    }
    100% {
        transform: rotate(0deg) scale(1);
    }
}

.animate-button {
    animation: tiltShake 3s ease-in-out;
    animation-iteration-count: infinite;
}
