/* Music.css */
.card {
    /* width: 250px; */
    /* height: 250px; */
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s;
}

.card:hover {
    transform: scale(1.2);
    box-shadow: 0px 5px 15px rgba(0,0,0,0.3);
}

.card-active {
    box-shadow: 0px 5px 15px rgba(0,0,0,0.3);
}

.truncate-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
